@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/Inter/styles.css";

/*** GLOBAL STYLES ***/
body {
  margin: 0;
  font-family: "Open sans", "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: -0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #394b59;
}

body.redesign {
  font-family: "Inter", "Open sans", "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
}

body *,
body *::before,
body *::after {
  font-family: inherit;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mask-image {
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
  mask-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
}

/*** HANDSONTABLE STYLES ***/

.handsontable {
  font-size: 14px !important;
}

.handsontable thead th {
  background-color: #f5f8fa !important;
  color: #5c7080 !important;
  font-weight: bold !important;
}

.handsontable tbody th {
  background-color: #f5f8fa !important;
  color: #bfccd6 !important;
}

.handsontable tbody tr td:hover {
  background-color: #fafafa !important;
  color: rgb(57, 75, 89) !important;
}

.handsontable tbody tr td.error-cell {
  background-color: rgb(248, 224, 226);
}

.handsontable tbody tr td.error-cell:hover {
  background-color: rgb(247, 204, 207) !important;
}

.handsontable tbody tr td.warning-cell {
  background-color: lightgoldenrodyellow;
}

.handsontable tbody tr td.warning-cell:hover {
  background-color: rgb(238, 238, 195) !important;
}

.handsontable tbody tr td.info-cell {
  background-color: aliceblue;
}

.handsontable tbody tr td.info-cell:hover {
  background-color: rgb(224, 236, 247) !important;
}

/* TODO: Remove !important, it's difficul to scale because of high specificity */
.handsontable tbody tr td.is-readOnly,
.handsontable tbody tr td.is-readOnly:hover {
  background-color: #f5f5f5 !important;
  color: #a5a5a5 !important;
}

/*** BOOTSTRAP ***/
.modal-content {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(247, 252, 255, 1) 100%
  );
  border: 0;
}

.alertModal .modal-content,
.fileUploadModal .modal-content {
  min-width: inherit;
}

.modal-footer {
  border: none;
}

.btn {
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 9%) 0px 2px 4px 1px;
}

.react-toggle {
  margin-top: 1px;
}

.react-toggle-track {
  width: 35px !important;
  height: 19px !important;
  background-color: #999 !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27 !important;
}

.react-toggle-thumb {
  height: 17px !important;
  width: 17px !important;
  border: 1px solid #999 !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 16px !important;
}

.modalContainer {
  margin: 32px;
  color: rgb(247, 250, 251);
}

/* TODO: Separate modal steps styles into different files (modularize code to make it increase cohesion) */
.fullScreenModal {
  max-width: 1440px !important;
  width: 90%;
  height: fit-content;
  margin: 35px auto;
}

.fileSelectContainer {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  font-size: 16px;
}

.fileSelectContainerGrid {
  display: grid;
  grid-template-columns: max-content 1px 1fr;
  gap: 16px;
  align-items: center;
  padding: 30px;
  border-width: 3px;
  border-radius: 2px;
  border-color: #dbe5f0;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.fileSelectDivider {
  width: 1px;
  height: 90%;
  background-color: #dbe5f0;
}

.fileSelectDescriptionText {
  margin: 0;
  color: rgb(102, 125, 141);
}

.fileSelectButtonDescriptionText {
  margin-top: 8px;
  margin-bottom: 0;
  color: rgb(102, 125, 141);
}

@media screen and (max-width: 590px) {
  .fileSelectContainerGrid {
    grid-template-columns: 1fr;
  }

  .fileSelectDivider {
    width: 100%;
    height: 1px;
    background-color: #dbe5f0;
  }

  .fileSelectButton {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }
}

.rowSelectorContainer {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin: 0px 0px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rowSelectorTitle {
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-right: auto;
}

.rowSelectorButton {
  padding: 4px 8px;
}

@media screen and (max-width: 849px) {
  .rowSelectorContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .rowSelectorTitle {
    flex-basis: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .rowSelectorButton {
    width: 100%;
  }
}

.encodingSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: 4px;
}

.encodingSelectorField {
  width: 250px;
}

@media screen and (max-width: 550px) {
  .encodingSelectorField {
    width: 100%;
  }
}

.columnMatchModalCardBody .columnMatchModalCardRightColumnButtonDiv .btn {
  margin-right: 8px;
}

@media screen and (max-width: 780px) {
  .columnMatchModalCardBody {
    flex-wrap: wrap;
  }

  .columnMatchModalCardBody .col {
    flex-basis: 100%;
  }

  .columnMatchModalCardRightColumnList {
    margin-top: 8px;
  }

  .columnMatchModalCardBody .columnMatchModalCardRightColumnButtonDiv {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .columnMatchModalCardBody .columnMatchModalCardRightColumnButtonDiv .btn {
    margin-right: 0;
  }
}

.modalTitle {
  border-bottom: none;
}

.modalHeader {
  border-bottom: none !important;
  align-items: center;
  padding: 40px;
  gap: 16px;
}

.modalHeader .close {
  margin-left: 0;
  align-self: flex-start;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modalFooter {
  height: 108px;
}

.modalFooterSecondaryButton {
  position: absolute;
  left: 32px;
  font-size: 1rem;
  height: auto;
  font-weight: 500;
  padding: 0.6em 1em;
}

.modalFooterAlternateButton {
  font-size: 1rem;
  height: auto;
  font-weight: 500;
  padding: 0.6em 1em;
}

.modalFooterPrimaryButton {
  background-color: rgb(56, 123, 247);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(56, 123, 247);
  font-size: 1rem;
  height: auto;
  font-weight: 500;
  padding: 0.6em 1em;
  margin-right: 20px;
  margin-left: 20px;
}

.modalBodyElement {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  font-size: 16px;
}

.dataMetadataModalContainer {
  display: flex;
  width: 100%;
  /* Remove if we want to revert changes to metadata screen */
  flex-direction: column;
}

.dataMetadataModalLeftChild {
  width: 315px;
  display: block;
}

.dataMetadataModalRightChild {
  height: auto;
  overflow: auto;
  flex: 1;
  display: block;
}

.dataMetadataModalLeftChildLabel {
  position: relative;
  padding-right: 10px;
  margin-bottom: 24px;
  margin-top: 28px;
}

.dataMetadataModalDropdown {
  width: 100%;
  margin-bottom: 10px;
}

.columnMatchModalCard {
  align-items: stretch;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px -12px rgba(0, 0, 0, 0.25),
    0 1px 4px 0 rgba(0, 0, 0, 0.075);
  margin-bottom: 10px;
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.columnMatchModalCardMinHeight {
  min-height: 206px;
}

.columnMatchModalCardBody {
  display: flex;
  padding: 0;
}

.columnMatchModalCardRightColumnButtonDiv {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 0px;
  left: 16px;
}

.columnMatchModalCardRightColumnList {
  list-style: none;
  padding-left: 0;
}

.columnMatchModalCardTable {
  table-layout: fixed;
  margin-bottom: 0 !important;
  min-height: 50px;
}

.columnMatchModalCardTable tbody tr {
  height: 35;
}

.alertModalBackdrop {
  background-color: rgba(78, 92, 107, 0.15);
}

.table th,
.table td {
  vertical-align: middle !important;
}

.helpText {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 2em;
}

.problemsToggleSpan {
  vertical-align: middle;
  margin-left: 5px;
}

.fileUploadModal {
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(78, 92, 107, 0.2);
}

.dataReviewModalBody {
  height: 67vh;
  /* overflow: hidden; */
  padding: 0;
}

.alertModal {
  z-index: 30001000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(78, 92, 107, 0.2);
}

.alertModalMessage {
  margin: 0;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.01em;
  font-size: 18px;
  line-height: 1.42857143;
  margin: 0;
  padding: 16px;
  height: 100%;
  width: 100%;
}

.alertModalFooter {
  padding: 0;
}

.alertModalMessageList {
  flex: 1;
  display: flex;
  font-size: 14px;
  padding: 30px;
  text-align: center;
  background-color: #f5f8fa;
  list-style: none;
}

.alertModalMessageListItemLeft {
  width: 50%;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 0px;
  padding-right: 15px;
  text-align: center;
  background-color: #f5f8fa;
  border-right: 1px solid #e3e3e3;
}

.alertModalMessageListItemRight {
  width: 50%;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: center;
  background-color: #f5f8fa;
}

.alertModalMessageListItemRightPaddingLeft {
  padding-left: 15px;
}

.alertModalListItemText {
  text-align: left;
}

.alertModalPrimaryButton {
  width: 100%;
  background-color: rgb(56, 123, 247);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(56, 123, 247);
  font-size: 1rem;
  height: auto;
  font-weight: 500;
  padding: 0.6em 1em;
}

.alertModalSecondaryButton {
  width: 100%;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  color: #667d8d;
  font-size: 1rem;
  height: auto;
  font-weight: 500;
  padding: 0.6em 1em;
}

.alertModalSecondaryButton:hover {
  background-color: #f0f0f0;
  color: #667d8d;
}

.spinner {
  margin: auto;
  margin-top: 40px;
  margin-bottom: 80px;
}

.fileUploadSpinnerText {
  vertical-align: super;
  margin-left: 12px;
}

.fileUploadSelectText {
  padding-bottom: 8px;
}

.fileUploadSelectField {
  width: 100%;
}

.react-toggle {
  vertical-align: middle;
}

.metadata-modal-header-select {
  width: 35px;
  position: absolute;
  left: -20px;
  top: 26px;
  flex-direction: column;
  border-bottom: 1px solid #ced4da;
}

.metadata-modal-header-select .input-group-text {
  border-radius: 0;
  border-bottom: 0;
  height: 23px;
}

.upsellModal .modal-dialog {
  max-width: 700px;
}
.productComparisonTableContainer {
  border-radius: 8px;
  border: 1px solid #bcccdc;
  border-collapse: separate;
  background-color: #f5f8fa;
  box-shadow: 0px 3px 3px lightgray;
}

.productComparisonTable {
  background-color: #f5f8fa;
  margin: 8px 0px;
}

.productComparisonTable th {
  border-top: none;
  text-align: center;
  padding-top: 1rem;
}

.productComparisonTable td {
  text-align: center;
}

.productComparisonTable td:nth-child(1) {
  text-align: left;
  padding-left: 1rem;
}

.productComparisonTableContainer a {
  color: black;
}

.productIcon {
  margin-bottom: 0.25rem;
}

.redesign .modal-header .close {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  font-size: 28px;
}
